import { mapState, mapActions, mapGetters } from 'vuex'
import BigNumber from 'bignumber.js'
import { formatNumber } from 'accounting'

import Spinner from '@/components/lib/Spinner/Spinner.vue'
import BaseField from '@/components/lib/BaseField/BaseField.vue'

export default {
  components: {
    Spinner,
    BaseField
  },
  props: {
  },
  data () {
    return {
      searchWord: '',
      expandedGroup: []
    }
  },
  computed: {
    ...mapState({
      fees: state => state.fees.fees.value,
      markets: state => state.fees.markets.value
    }),
    ...mapGetters({
      getMarketBySymbol: 'fees/getMarketBySymbol',
      isLoadedMarkets: 'fees/isMarketsLoaded',
      isFeesLoaded: 'fees/isFeesLoaded',
      getCurrencyBySymbol: 'currencies/getCurrencyBySymbol'
    }),
    feesItems () {
      return this.fees.map(item => ({
        ...item,
        market: this.getMarketBySymbol(item.symbol).name,
        icon: this.getCurrencyBySymbol(this.getMarketBySymbol(item.symbol).baseCurrency).icon,
        background: this.getCurrencyBySymbol(this.getMarketBySymbol(item.symbol).baseCurrency).background
      })).filter(e => {
        const market = this.markets.find(m => m.symbol === e.symbol)
        return market && !market.locked
      })
    },
    filteredFeesItems () {
      if (this.searchWord) {
        return this.feesItems.filter((item) => {
          return item.market.toLowerCase().includes(this.searchWord.toLowerCase())
        })
      } else {
        return this.feesItems
      }
    },
    feeLevels () {
      return this.fees && this.fees[0]
    },
    feeLevel1 () {
      return `${this.formatOrNil(this.feeLevels.fees[0].min)} - ${this.formatOrNil(this.feeLevels.fees[0].max)} <br> ${this.i18nSection('feeVolumePeriod')}`
    },
    feeLevel2 () {
      return `${this.formatOrNil(this.feeLevels.fees[1].min)} - ${this.formatOrNil(this.feeLevels.fees[1].max)} <br> ${this.i18nSection('feeVolumePeriod')}`
    },
    feeLevel3 () {
      return `&ge; ${this.formatOrNil(this.feeLevels.fees[2].min)} <br> ${this.i18nSection('feeVolumePeriod')}`
    }
  },
  methods: {
    formatNumber,
    ...mapActions({
      loadFees: 'fees/loadFees',
      loadMarkets: 'fees/loadMarkets'
    }),
    isExpanded (key) {
      return this.expandedGroup.indexOf(key) !== -1
    },
    toggleExpansion (key) {
      if (this.isExpanded(key)) {
        this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
      } else {
        this.expandedGroup.push(key)
      }
    },
    getFeesLevel (min, max) {
      if (max !== null) {
        return `${this.formatOrNil(min)} - ${this.formatOrNil(max)} ${this.i18nSection('feeVolumePeriod')}`
      } else {
        return `${this.formatOrNil(min)} ${this.i18nSection('feeVolumePeriod')}`
      }
    },
    formatOrNil (value, n) {
      return value
        ? this.formatNumber(value, n, ',')
        : '0'
    },
    getFeeAmount (value) {
      return new BigNumber(value).times(100).toString()
    }
  },
  async serverPrefetch () {
    await this.loadMarkets()
    await this.loadFees()
  },
  async created () {
    if (!this.isFeesLoaded) {
      await this.loadMarkets()
      await this.loadFees()
    }
  }
}
